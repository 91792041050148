{
  "a11y": {
    "loading_page": "Завантаження сторінки, будь ласка, зачекайте",
    "loading_titled_page": "Завантаження сторінки {0}, будь ласка, зачекайте",
    "locale_changed": "Мову змінено на {0}",
    "locale_changing": "Зміна мови, будь ласка, зачекайте",
    "route_loaded": "Сторінка {0} завантажена"
  },
  "account": {
    "avatar_description": "Аватар {0}",
    "blocked_by": "Ви заблоковані цим користувачем.",
    "blocked_domains": "Заблоковані домени",
    "blocked_users": "Заблоковані користувачі",
    "blocking": "Заблоковані",
    "bot": "БОТ",
    "favourites": "Улюблені",
    "follow": "Підписатися",
    "follow_back": "Підписатися",
    "follow_requested": "Запити",
    "followers": "Підписники",
    "followers_count": "{0} підписників|{0} підписник|{0} підписники|{0} підписників",
    "following": "Підписаний",
    "following_count": "{0} підписок",
    "follows_you": "Підписаний на вас",
    "go_to_profile": "Перейти до профілю",
    "joined": "Зареєстровано",
    "moved_title": "вказав, що його новий обліковий запис тепер:",
    "muted_users": "Приховані користувачі",
    "muting": "Приховано",
    "mutuals": "Взаємно",
    "pinned": "Закріплені дописи",
    "posts": "Дописи",
    "posts_count": "{0} дописів|{0} допис|{0} дописи|{0} дописів",
    "profile_description": "Заголовок профілю {0}",
    "profile_unavailable": "Профіль недоступний",
    "unblock": "Розблокувати",
    "unfollow": "Відписатися",
    "unmute": "Приховати"
  },
  "action": {
    "apply": "Застосувати",
    "bookmark": "Додати в закладки",
    "bookmarked": "Додано з закладки",
    "boost": "Поширити",
    "boost_count": "{0}",
    "boosted": "Поширено",
    "clear_upload_failed": "Очистити помилки завантаження",
    "close": "Закрити",
    "compose": "Написати",
    "confirm": "Підтвердити",
    "edit": "Редагувати",
    "enter_app": "Перейти до додатку",
    "favourite": "Вподобати",
    "favourite_count": "{0}",
    "favourited": "Подобається",
    "more": "Більше",
    "next": "Наступний",
    "prev": "Попередній",
    "publish": "Опублікувати",
    "reply": "Відповісти",
    "reply_count": "{0}",
    "reset": "Скинути",
    "save": "Зберегти",
    "save_changes": "Зберегти зміни",
    "sign_in": "Увійти",
    "switch_account": "Змінити обліковий запис",
    "vote": "Голосувати"
  },
  "app_desc_short": "Спритний веб-клієнт для Mastodon",
  "app_logo": "Elk лого",
  "app_name": "Elk",
  "attachment": {
    "edit_title": "Опис",
    "remove_label": "Видалити вкладення"
  },
  "command": {
    "activate": "Активувати",
    "complete": "Вибрати",
    "compose_desc": "Написати новий допис",
    "n-people-in-the-past-n-days": "{0} користувачів за останні {1} днів",
    "select_lang": "Змінити мову",
    "sign_in_desc": "Додати існуючий обліковий запис",
    "switch_account": "Змінити обліковий запис на {0}",
    "switch_account_desc": "Змінити активний обліковий запис",
    "toggle_dark_mode": "Перемкнути темний режим",
    "toggle_zen_mode": "Перемкнути режим Zen"
  },
  "common": {
    "end_of_list": "Кінець списку",
    "error": "ПОМИЛКА",
    "in": "в",
    "not_found": "404 Не знайдено",
    "offline_desc": "Схоже, ви відключені від мережі. Будь ласка, перевірте ваше підключення до мережі."
  },
  "compose": {
    "draft_title": "Чернетка {0}",
    "drafts": "Чернетки ({v})"
  },
  "confirm": {
    "common": {
      "cancel": "Відмінити",
      "confirm": "Так"
    },
    "delete_posts": {
      "cancel": "Скасувати",
      "confirm": "Видалити",
      "title": "Ви впевнені, що хочете видалити цей допис?"
    }
  },
  "conversation": {
    "with": "з"
  },
  "error": {
    "account_not_found": "Обліковий запис {0} не знайдено",
    "explore-list-empty": "Зараз нічого не в тренді. Перевірте пізніше!",
    "file_size_cannot_exceed_n_mb": "Розмір файлу не може перевищувати {0} Мб",
    "sign_in_error": "Не вдалося підключитися до сервера.",
    "status_not_found": "Допис не знайдено",
    "unsupported_file_format": "Непідтримуваний формат файлу"
  },
  "help": {
    "desc_highlight": "Очікуйте деякі помилки та відсутні функції тут і там.",
    "desc_para1": "Дякуємо за ваш інтерес до випробування Elk, нашого універсального клієнта Mastodon, який ще у розробці!",
    "desc_para2": "Ми наполегливо працюємо над розробкою та вдосконалюємо його. Ми відкриємо вихідний код, коли він буде готовий для загального використання.",
    "desc_para3": "Щоб допомогти прискорити розробку, ви можете спонсорувати членів нашої команди за посиланнями нижче. Сподіваємося, вам сподобається Elk!",
    "desc_para4": "До цього, якщо ви хочете допомогти з тестуванням, надіслати відгук або зробити внесок,",
    "desc_para5": "зв’яжіться з нами на GitHub",
    "desc_para6": "і долучіться.",
    "title": "Elk у попередньому перегляді!"
  },
  "language": {
    "search": "Пошук"
  },
  "menu": {
    "block_account": "Заблокувати {0}",
    "block_domain": "Заблокувати домен {0}",
    "copy_link_to_post": "Скопіювати посилання на цей допис",
    "delete": "Видалити",
    "delete_and_redraft": "Видалити і переписати",
    "direct_message_account": "Пряме повідомлення {0}",
    "edit": "Редагувати",
    "mention_account": "Згадати {0}",
    "mute_account": "Приховати {0}",
    "mute_conversation": "Ігнорувати цей допис",
    "open_in_original_site": "Відкрити на оригінальному сайті",
    "pin_on_profile": "Закріпити на профілі",
    "share_post": "Поділіться цим дописом",
    "show_untranslated": "Показати без перекладу",
    "toggle_theme": {
      "dark": "Увімкнути темний режим",
      "light": "Увімкнути світлий режим"
    },
    "translate_post": "Перекласти допис",
    "unblock_account": "Розблокувати {0}",
    "unblock_domain": "Розблокувати домен {0}",
    "unmute_account": "Не приховувати {0}",
    "unmute_conversation": "Не ігнорувати цей допис",
    "unpin_on_profile": "Відкріпити з профілю"
  },
  "nav": {
    "back": "Назад",
    "blocked_domains": "Заблоковані домени",
    "blocked_users": "Заблоковані користувачі",
    "bookmarks": "Закладки",
    "built_at": "Оновлено {0}",
    "conversations": "Прямі повідомлення",
    "explore": "Огляд",
    "favourites": "Вподобане",
    "federated": "Глобальна",
    "home": "Головна",
    "local": "Локальна",
    "muted_users": "Приховані користувачі",
    "notifications": "Сповіщення",
    "profile": "Профіль",
    "search": "Пошук",
    "select_feature_flags": "Налаштування функцій",
    "select_font_size": "Вибрати розмір шрифту",
    "select_language": "Вибрати мову",
    "settings": "Налаштування",
    "show_intro": "Показати інтро",
    "toggle_theme": "Змінити тему",
    "zen_mode": "Zen-режим"
  },
  "notification": {
    "favourited_post": "додали ваший допис до вибраного",
    "followed_you": "підписались на вас",
    "followed_you_count": "{0} людей підписалися на вас|{0} людина підписалися на вас|{0} людини підписалися на вас|{0} людей підписалися на вас",
    "missing_type": "ВІДСУТНІЙ notification.type:",
    "reblogged_post": "поширили ваш допис",
    "request_to_follow": "попросили підписатися на вас",
    "signed_up": "зареєструвалися",
    "update_status": "оновили свою публікацію"
  },
  "placeholder": {
    "content_warning": "Напишіть ваше попередження тут",
    "default_1": "Що у вас на думці?",
    "reply_to_account": "Відповісти {0}",
    "replying": "Відповідь",
    "the_thread": "нитка"
  },
  "pwa": {
    "dismiss": "Закрити",
    "title": "Доступна нова версія Elk!",
    "update": "Оновити",
    "update_available_short": "Оновити Elk"
  },
  "search": {
    "search_desc": "Пошук користувачів та хештеґів",
    "search_empty": "Не вдалося знайти нічого, що відповідає цим пошуковим термінам"
  },
  "settings": {
    "about": {
      "label": "Про нас"
    },
    "account_settings": {
      "description": "Відредагуйте налаштування облікового запису використовуєчи інтерфейс Mastodon",
      "label": "Налаштування облікового запису"
    },
    "feature_flags": {
      "github_cards": "GitHub картки",
      "title": "Експериментальні налаштування"
    },
    "interface": {
      "color_mode": "Кольорова тема",
      "dark_mode": "Темна",
      "default": " (за замовчуванням)",
      "font_size": "Розмір шрифта",
      "label": "Інтерфейс",
      "light_mode": "Світла"
    },
    "language": {
      "display_language": "Мова інтерфейсу",
      "label": "Мова"
    },
    "notifications": {
      "label": "Сповіщення",
      "notifications": {
        "label": "Налаштування сповіщень"
      },
      "push_notifications": {
        "alerts": {
          "favourite": "Вподобання",
          "follow": "Нові підписники",
          "mention": "Згадки",
          "poll": "Опитування",
          "reblog": "Поширення вашого допису",
          "title": "Які сповіщення отримувати?"
        },
        "description": "Отримуйте сповіщення, навіть якщо ви не використовуєте Elk.",
        "instructions": "Не забудьте зберегти зміни за допомогою кнопки \"@:settings.notifications.push_notifications.save_settings{'\"'}!",
        "label": "Налаштування push-сповіщень",
        "policy": {
          "all": "Від будь-кого",
          "followed": "Людей, за якими я стежу",
          "follower": "Людей, які слідкують за мною",
          "none": "Ні від кого",
          "title": "Від кого отримувати сповіщення?"
        },
        "save_settings": "Зберегти налаштування",
        "subscription_error": {
          "clear_error": "Очистити помилку",
          "permission_denied": "У дозволі відмовлено: увімкніть сповіщення у своєму браузері.",
          "request_error": "Під час запиту на підписку сталася помилка. Повторіть спробу, а якщо помилка не зникне, повідомте про проблему в репозиторій Elk.",
          "title": "Не вдалося підписатися на push-сповіщення"
        },
        "undo_settings": "Скасувати зміни",
        "unsubscribe": "Вимкнути push-сповіщення",
        "unsupported": "Ваш браузер не підтримує push-сповіщення.",
        "warning": {
          "enable_close": "Закрити",
          "enable_description": "Щоб отримувати сповіщення, коли Elk не відкрито, увімкніть push-сповіщення. Ви можете контролювати, які саме типи сповіщень генерують push-повідомлення, за допомогою кнопки \"@:settings.notifications.show_btn{'\"'} після ввімкнення.",
          "enable_desktop": "Увімкнути push-повідомлення",
          "enable_title": "Ніколи нічого не пропускайте",
          "re_auth": "Здається, ваш сервер не підтримує push-повідомлення. Спробуйте вийти та увійти знову, якщо це повідомлення все одно з’являється, зверніться до адміністратора свого сервера."
        }
      },
      "show_btn": "Перейти до налаштувань сповіщень"
    },
    "notifications_settings": "Сповіщення",
    "preferences": {
      "label": "Налаштування"
    },
    "profile": {
      "appearance": {
        "bio": "Про Вас",
        "description": "Редагувати аватар, ім'я, профіль, тощо.",
        "display_name": "Ім'я",
        "label": "Вигляд",
        "profile_metadata": "Метадані профілю",
        "profile_metadata_desc": "У вашому профілі можна відображати до 4 елементів у вигляді таблиці",
        "title": "Редагувати профіль"
      },
      "featured_tags": {
        "description": "Користувачі можуть фільтрувати ваші загальнодоступні дописи за цими хештеґами.",
        "label": "Рекомендовані хештеґи"
      },
      "label": "Профіль"
    },
    "select_a_settings": "Виберіть налаштування",
    "users": {
      "export": "Експортувати токени користувачів",
      "import": "Імпортувати токени користувачів",
      "label": "Користувачі"
    }
  },
  "state": {
    "attachments_exceed_server_limit": "Кількість вкладених файлів перевищила ліміт на допис.",
    "attachments_limit_error": "Перевищено максимальну кількість вкладень",
    "edited": "(Відредаговано)",
    "editing": "Редагування",
    "loading": "Завантаження...",
    "upload_failed": "Помилка завантаження",
    "uploading": "Завантаження..."
  },
  "status": {
    "edited": "Редаговано {0}",
    "filter_hidden_phrase": "Відфільтровано",
    "filter_show_anyway": "Показати все одно",
    "img_alt": {
      "desc": "Опис зображення",
      "dismiss": "Закрити"
    },
    "poll": {
      "count": "{0} голосів|{0} голос|{0} голоси|{0} голосів",
      "ends": "завершується {0}",
      "finished": "зевершилось {0}"
    },
    "reblogged": "{0} поширив",
    "replying_to": "Відповідає {0}",
    "show_full_thread": "Показати потік дописів",
    "someone": "комусь",
    "spoiler_show_less": "Показувати менше",
    "spoiler_show_more": "Показати більше",
    "try_original_site": "Спробуйте оригінальний сайт"
  },
  "status_history": {
    "created": "створено {0}",
    "edited": "редаговано {0}"
  },
  "tab": {
    "for_you": "Для вас",
    "hashtags": "Хештеґи",
    "media": "Медіа",
    "news": "Новини",
    "notifications_all": "Усі",
    "notifications_mention": "Згадки",
    "posts": "Дописи",
    "posts_with_replies": "Дописи та відповіді"
  },
  "tag": {
    "follow": "Стежити",
    "follow_label": "Стежити за хештеґом {0}",
    "unfollow": "Не стежити",
    "unfollow_label": "Не стежити за хештеґом {0}"
  },
  "time_ago_options": {
    "day_future": "через {n} днів|через {n} день|через {n} дні|через {n} днів",
    "day_past": "{n} днів томо|{n} день тому|{n} дні тому|{n} днів тому",
    "hour_future": "через {n} годин|через {n} годину|через {n} години|через {n} годин",
    "hour_past": "{n} годин тому|{n} годину тому|{n} години тому|{n} годин тому",
    "just_now": "тільки що",
    "minute_future": "через {n} хвилин|через {n} хвилину|через {n} хвилини|через {n} хвилин",
    "minute_past": "{n} хвилин тому|{n} хвилину тому|{n} хвилини тому|{n} хвилин тому",
    "month_future": "через {n} місяців|через {n} місяць|через {n} місяці|через {n} місяців",
    "month_past": "{n} місяців тому|{n} місяць тому|{n} місяці тому|{n} місяців тому",
    "second_future": "тільки що|через {n} секунду|через {n} секунди|через {n} секунд",
    "second_past": "тільки що|{n} секунду тому|{n} секунди тому|{n} секунд тому",
    "short_day_future": "через {n}дн",
    "short_day_past": "{n}дн",
    "short_hour_future": "через {n}год",
    "short_hour_past": "{n}год",
    "short_minute_future": "через {n}хв",
    "short_minute_past": "{n}хв",
    "short_month_future": "через {n}міс",
    "short_month_past": "{n}міс",
    "short_second_future": "через {n}сек",
    "short_second_past": "{n}сек",
    "short_week_future": "через {n}тиж",
    "short_week_past": "{n}тиж",
    "short_year_future": "через {n}р",
    "short_year_past": "{n}р",
    "week_future": "через {n} тижнів|через {n} тиждень|через {n} тижні|через {n} тижнів",
    "week_past": "{n} тижнів тому|{n} тиждень тому|{n} тижні тому|{n} тижнів тому",
    "year_future": "через {n} років|через {n} рік|через {n} роки|через {n} років",
    "year_past": "{n} років тому|{n} рік тому|{n} роки тому|{n} років тому"
  },
  "timeline": {
    "show_new_items": "Показати {v} нових дописів|Показати {v} новий допис|Показати {v} нових дописи|Показати {v} нових дописів",
    "view_older_posts": "Старіші дописи з інших серверів можуть не відображатися."
  },
  "title": {
    "federated_timeline": "Глобальна стрічка",
    "local_timeline": "Локальна стрічка"
  },
  "tooltip": {
    "add_content_warning": "Додати попередження про вміст",
    "add_media": "Додати зображення, відео або аудіо",
    "change_content_visibility": "Змінити видимість вмісту",
    "change_language": "Змінити мову",
    "emoji": "Емоджі",
    "explore_links_intro": "Ці новини розповідають історії про людей на цих та інших серверах децентралізованої мережі прямо зараз.",
    "explore_posts_intro": "Ці дописи з цього та інших серверів децентралізованої мережі зараз набирають популярності на цьому сервері.",
    "explore_tags_intro": "Ці хештеґи зараз набирають популярності серед людей на цьому та інших серверах децентралізованої мережі.",
    "toggle_code_block": "Блок коду"
  },
  "user": {
    "add_existing": "Додати аккаунт",
    "server_address_label": "Адреса серверу Mastodon",
    "sign_in_desc": "Увійдіть, щоб слідкувати за профілями або хештеґами, додавати до обраного, розповсюджувати і відповідати на дописи, або взаємодіяти з вашого аккаунту на іншому сервері.",
    "sign_in_notice_title": "Перегляд публічних даних {0}",
    "sign_out_account": "Вийти з {0}",
    "tip_no_account": "Якщо у вас ще немає аккаунту Mastodon, {0}.",
    "tip_register_account": "оберіть сервер і зареєструйтесь"
  },
  "visibility": {
    "direct": "Лише згадані користувачі",
    "direct_desc": "Показати лише згаданим користувачам",
    "private": "Тільки для підписників",
    "private_desc": "Показати лише підписникам",
    "public": "Публічно",
    "public_desc": "Видимий для всіх",
    "unlisted": "Прихований",
    "unlisted_desc": "Видимий для всіх, але не через можливості виявлення"
  }
}
